import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App.js';
import '@fontsource/roboto';
import '@fontsource/source-sans-pro';

const app = ReactDOM.createRoot(document.getElementById('app'));

app.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);
