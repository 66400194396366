import axios from 'axios';

export const resetPassword = async ({ email, callback }) => {
  try {
    const { status } = await axios.post('/api/v1/reset-password', {
      email,
    });

    if (status === 204) {
      callback({
        type: 'success',
        message:
          'We have sent you an e-mail containing your password reset link. Follow the link to reset your password',
      });
    }
  } catch (e) {
    if (e.response.status === 422) {
      callback({ type: 'error', message: e.response.data.error });
    }

    if (e.response.status === 500) {
      callback({
        type: 'error',
        message: 'Something went wrong, please contact support!',
      });
    }

    console.error(e);
  }
};
