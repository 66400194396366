/**
 *
 * ResetPassword
 *
 */

import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/shiphawk-logo-navy-retina.png';
import styles from '../styles.module.css';
import Button from '../../components/Button/index.js';
import Notification from '../../components/Notification/index.js';
import FormGroup from '../../components/FormGroup/index.js';
import { ReactComponent as EmailIcon } from '../../svg/email.svg';
import { validateEmail } from '../helpers.js';
import { resetPassword } from './actions.js';

function ResetPassword() {
  const [emailError, setEmailError] = useState('');
  const [backendNotification, setBackendNotification] = useState({});

  const emailRef = useRef();

  const submitForm = useCallback(
    (event) => {
      event.preventDefault();

      const emailData = emailRef.current.value.trim();

      if (!emailData || !validateEmail(emailData)) {
        setEmailError('Please enter a valid email address');
        return;
      }

      setEmailError('');

      resetPassword({
        email: emailData,
        callback: setBackendNotification,
      });
    },
    [resetPassword, emailRef],
  );

  return (
    <div className={styles.container}>
      <Link to="/sign-in">
        <img data-testid="logoLink" className={styles.logo} alt="" src={logo} />
      </Link>
      <div className={styles.headerBlock}>
        <h3>Forgot Your Password?</h3>

        <p>
          Enter your email below and we will send you instructions on how to
          update your password
        </p>
      </div>

      {Object.keys(backendNotification).length > 0 && (
        <div className={styles.notification}>
          <Notification
            message={backendNotification.message}
            type={backendNotification.type}
          />
        </div>
      )}

      <form onSubmit={submitForm}>
        <div className={styles.formGroup}>
          <FormGroup
            label="Email"
            type="email"
            icon={<EmailIcon />}
            error={emailError}
            reference={emailRef}
          />
        </div>

        <div className={styles.button}>
          <Button text="Reset Password" submit />
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
