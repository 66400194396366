import axios from 'axios';

export const login = async ({
  email,
  password,
  otp,
  setError,
  setIsOtpRequired,
  setOtpDetails,
}) => {
  try {
    const { status, data } = await axios.post('/api/v1/authenticate', {
      email,
      password,
      otp,
    });

    if (status === 201) {
      if (data.otp_required_for_login) {
        setIsOtpRequired(true);
        setOtpDetails({
          email,
          password,
          otpSecret: data.otp_secret,
          otpProvisioningUri: data.otp_provisioning_uri,
        });
      } else {
        window.location.replace(data.redirect_url);
        return;
      }
    }
  } catch (e) {
    if (e.response.status === 422) {
      setError(e.response.data.error);
    }

    if (e.response.status === 500) {
      setError('Something went wrong, please contact support!');
    }

    console.error(e);
  }
};
