import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import styles from '../../styles.module.css';
import Button from '../../../components/Button/index.js';
import Notification from '../../../components/Notification/index.js';
import FormGroup from '../../../components/FormGroup/index.js';
import { ReactComponent as PasswordIcon } from '../../../svg/password.svg';

const HELP_PAGE_URL = 'https://shiphawk.atlassian.net/l/cp/KWdfvRGi';

function TwoFactorSignIn({ otpDetails, submit }) {
  const [otpError, setOtpError] = useState('');
  const [backendError, setBackendError] = useState('');
  const [isOtpSetupShown, setIsOtpSetupShown] = useState(false);
  const [isOtpQrCodeShown, setIsOtpQrCodeShown] = useState(false);

  const otpRef = useRef();

  useEffect(() => {
    if (otpDetails.otpSecret) {
      setIsOtpSetupShown(true);
    }
  }, [otpDetails]);

  const showQrCode = useCallback(() => setIsOtpQrCodeShown(true), []);

  const submitForm = useCallback(
    (event) => {
      event.preventDefault();

      const otpData = otpRef.current.value.trim();

      if (!otpData) {
        setOtpError(
          'Please enter verification code from your authenticator app',
        );
        return;
      }

      if (otpData) {
        setOtpError('');
      }

      if (otpData) {
        submit({
          email: otpDetails.email,
          password: otpDetails.password,
          otp: otpData,
          setError: setBackendError,
        });
      }
    },
    [otpDetails, submit, otpRef],
  );

  return (
    <div>
      {isOtpSetupShown && (
        <div>
          {!isOtpQrCodeShown && (
            <div className={styles.headerBlock}>
              <p>
                Your ShipHawk Administrator has enabled Two-Factor
                Authentication (2FA) for this account.
              </p>
              <div className={styles.button}>
                <Button text="Generate code for 2FA" onClick={showQrCode} />
              </div>
            </div>
          )}

          {isOtpQrCodeShown && (
            <div className={styles.qrcodeContainer}>
              <QRCodeSVG
                level="M"
                size={140}
                value={otpDetails.otpProvisioningUri}
              />
              <p className={styles.otpSecret}>{otpDetails.otpSecret}</p>
              <p className={styles.helpBlock}>
                Scan QR code with your authenticator app or copy the code above
                to your authenticator app. <br /> <br /> If you do not have one
                we recommend installing <b>Google Authenticator</b> from your
                mobile store. <br /> <br /> Once your authenticator app
                generates a 6 digit code, enter it here to activate your 2FA.
              </p>
            </div>
          )}
        </div>
      )}

      {!isOtpSetupShown && (
        <div className={styles.headerBlock}>
          <p>Enter the 6 digit code generated by your authenticator app</p>
        </div>
      )}

      {backendError && (
        <div className={styles.notification}>
          <Notification type="error" message={backendError} />
        </div>
      )}

      {(!isOtpSetupShown || isOtpQrCodeShown) && (
        <form onSubmit={submitForm}>
          <div className={styles.formGroup}>
            <FormGroup
              label="Verification Code"
              icon={<PasswordIcon />}
              error={otpError}
              reference={otpRef}
            />
          </div>

          <div className={styles.button}>
            <Button text="Log In" submit />
          </div>
        </form>
      )}

      {isOtpSetupShown && (
        <div className={styles.forgotLink}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            href={HELP_PAGE_URL}
          >
            Learn more about 2FA setup
          </a>
        </div>
      )}

      {!isOtpSetupShown && (
        <p className={styles.helpBlock}>
          If you have lost access to your authenticator app or encountering
          issues, please reach out to your ShipHawk system administrator for
          assistance in resetting your 2FA settings.
        </p>
      )}
    </div>
  );
}

TwoFactorSignIn.propTypes = {
  otpDetails: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default TwoFactorSignIn;
