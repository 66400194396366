/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import styles from './styles.module.css';

function NotFound() {
  return (
    <>
      <h1 className={styles.mainHeader}>404</h1>
      <h2 className={styles.secondHeader}>Page not found.</h2>
    </>
  );
}

export default NotFound;
