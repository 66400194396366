/**
 *
 * Notification
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { ReactComponent as AttentionSign } from '../../svg/attention.svg';
import { ReactComponent as CheckCircle } from '../../svg/checkCircle.svg';

function Notification({ message, type }) {
  const Icon = type === 'error' ? AttentionSign : CheckCircle;

  return (
    <div className={`${styles.notification} ${styles[type]}`}>
      <span className={styles.icon}>
        <Icon />
      </span>
      <span className={styles.text}>{message}</span>
    </div>
  );
}

Notification.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
  message: PropTypes.string.isRequired,
};

export default Notification;
