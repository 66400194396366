/**
 *
 * FormGroup
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function FormGroup(props) {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={props.type} className={styles.label}>
        {props.label}
        <span className={styles.asterisc}>*</span>
      </label>

      <div className={styles.inputBlock}>
        <input
          id={props.type}
          type={props.type}
          className={`${styles.input} ${
            props.error ? styles.inputWithError : ''
          }`}
          ref={props.reference}
        />
        <span className={styles.icon}>{props.icon}</span>
      </div>

      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  );
}

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['email', 'password']),
  reference: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
};

export default FormGroup;
