import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound/index.js';
import SignIn from './pages/SignIn/index.js';
import ResetPassword from './pages/ResetPassword/index.js';
import Layout from './components/Layout/index.js';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
      </Routes>
    </Layout>
  );
}

export default App;
