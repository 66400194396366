/**
 *
 * Layout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function Layout(props) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{props.children}</div>

      <div className={styles.footer}>
        <p>© {new Date().getFullYear()}</p>

        <a
          href="https://shiphawk.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.poweredBy}
        >
          ShipHawk
        </a>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
