/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function Button(props) {
  return (
    <button
      className={styles.button}
      type={props.submit ? 'submit' : 'button'}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

Button.propTypes = {
  submit: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  submit: false,
  onClick: () => {},
};

export default Button;
