import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../../styles.module.css';
import Button from '../../../components/Button/index.js';
import Notification from '../../../components/Notification/index.js';
import FormGroup from '../../../components/FormGroup/index.js';
import { ReactComponent as EmailIcon } from '../../../svg/email.svg';
import { ReactComponent as PasswordIcon } from '../../../svg/password.svg';
import { validateEmail } from '../../helpers.js';

function BasicSignIn({ submit }) {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [backendError, setBackendError] = useState('');

  const emailRef = useRef();
  const passwordRef = useRef();

  const submitForm = useCallback(
    (event) => {
      event.preventDefault();

      const emailData = emailRef.current.value.trim();
      const passwordData = passwordRef.current.value.trim();

      if (!emailData) {
        setEmailError('Please enter a valid email address');
      }
      if (!passwordData) {
        setPasswordError('Please enter a password');
        return;
      }

      if (emailData) {
        setEmailError('');
      }
      if (passwordData) {
        setPasswordError('');
      }

      const emailIsValid = validateEmail(emailData);

      if (emailData && !emailIsValid) {
        setEmailError('Please enter a valid email address');
      }

      if (emailData && emailIsValid && passwordData) {
        submit({
          email: emailData,
          password: passwordData,
          setError: setBackendError,
        });
      }
    },
    [submit, emailRef, passwordRef],
  );

  return (
    <div>
      <div className={styles.headerBlock}>
        <h2>Welcome Back</h2>

        <p>Enter your credentials to access your account</p>
      </div>

      {backendError && (
        <div className={styles.notification}>
          <Notification type="error" message={backendError} />
        </div>
      )}

      <form onSubmit={submitForm}>
        <div className={styles.formGroup}>
          <FormGroup
            label="Email"
            type="email"
            icon={<EmailIcon />}
            error={emailError}
            reference={emailRef}
          />
        </div>

        <div className={styles.formGroup}>
          <FormGroup
            label="Password"
            type="password"
            icon={<PasswordIcon />}
            error={passwordError}
            reference={passwordRef}
          />
        </div>

        <div className={styles.button}>
          <Button text="Log In" submit />
        </div>

        <div className={styles.forgotLink}>
          <Link to="/forgot-password" className={styles.link}>
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
}

BasicSignIn.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default BasicSignIn;
