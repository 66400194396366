/**
 *
 * SignIn
 *
 */

import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/shiphawk-logo-navy-retina.png';
import styles from '../styles.module.css';
import { login } from './actions.js';
import BasicSignIn from './components/BasicSignIn.js';
import TwoFactorSignIn from './components/TwoFactorSignIn.js';

function SignIn() {
  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [otpDetails, setOtpDetails] = useState({});

  const submitCallback = useCallback(
    (data) => login({ ...data, setIsOtpRequired, setOtpDetails }),
    [login],
  );

  return (
    <div className={styles.container}>
      <Link to="/sign-in">
        <img data-testid="logoLink" className={styles.logo} alt="" src={logo} />
      </Link>
      {!isOtpRequired && <BasicSignIn submit={submitCallback} />}
      {isOtpRequired && (
        <TwoFactorSignIn otpDetails={otpDetails} submit={submitCallback} />
      )}
    </div>
  );
}

export default SignIn;
